:root {
    /* COLOR */
    --color-white: #FFFFFF;
    --color-white-1: #C4C4C4;
    --color-white-2: #D6EBEB;
    --color-white-3: #FFFCEB;
    --color-black: #000000;
    --color-black-1: #1D1B1B;
    --color-black-2: #00121F;
    --color-black-3: #050505;
    --color-transparent: transparent;
    --color-blue: #003D6A;
    --color-darkBlue: #001D33;
    --color-yellow: #FFC000;
    --color-gray: #808080;
    --color-gray-1: #979797;
    --color-gray-2: #B3B3B3;
    --color-gray-3: #828282;
    --color-gray-4: #CCCCCC;
    --color-gray-5: #999;
    --color-gray-6: #666666;
    --color-grey16: #292929;
    --color-grey-1: #3A3738;
    --color-light-orange: #FD7156;
    --color-light-yellow: #FFEEC5;
    --color-light-yellow-1: #FFB100;
    --color-light-yellow-2: #fdf8ed;
    --color-light-red: #FFD9D1;
    --color-light-cyan: #D3FDFF;
    --color-light-green: #06807D;
    --color-sky-blue: #0098C8;

    /* Linear Gradient */
    --linear-greenYellow: linear-gradient(to right, #00A3A3, #FFB100);

    /* POSTER IMAGES */
    --poster-home: url("../../assets/images/poster/home-poster.png");
    --poster-influencer: url("../../assets/images/backgound/Influencers/Influencers.png");

    --poster-goa: url("../../assets/images/poster/TripPage/GoaPoster.png");

    --poster-trip-description-goa: url("../../assets/images/poster/TripDescriptionPage/Goa/GoaPoster.png");

    /* SHADOW */
    --shadow-poster-heading: 0px 4px 24px rgba(0, 0, 0, 0.34);
    --shadow-poster-searchBar: 0px 2px 74px 2px rgba(0, 0, 0, 0.10);
    --shadow-travelCard: 0px 11px 34px 0px rgba(0, 61, 106, 0.13);
    --shadow-blogCard-horizontal: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --shadow-community-circle-card: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    --shadow-contact: 0px 4px 4px rgba(0, 0, 0, 0.25);

    /* FONT */
    --font-poppins: "Poppins";

}

p {
    margin-top: 0;
    margin-bottom: 0rem;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------- topNavBar.js -----------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.main-navbar {
    background: var(--color-black-2);

}

.modal-backdrop {
    --bs-backdrop-zindex: none !important;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}



.navbar-expand-md .navbar-nav .nav-link {
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------- HOMEPAGE -------------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.image-background {
    height: calc(75vh - 66px);
    background-image: var(--poster-home);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home-poster-heading {
    color: var(--color-white);
    text-align: center;
    text-shadow: var(--shadow-poster-heading);
    font-family: var(--font-poppins);
    font-size: 87px;
    font-style: normal;
    font-weight: 800;
    line-height: 81px;
    letter-spacing: -0.87px;
}

@media (max-width: 767px) {
    .home-poster-heading {
        color: var(--color-white);
        text-align: center;
        text-shadow: var(--shadow-poster-heading);
        font-family: var(--font-poppins);
        font-size: 57px;
        font-style: normal;
        font-weight: 800;
        line-height: 81px;
        letter-spacing: -0.87px;
    }
}

.text-color-yellow {
    color: var(--color-yellow);
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- circleSearchButton.js ------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.circle-search {
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 20px;
    color: var(--color-white);
    background-color: var(--color-blue);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------ dropdownFormat.js ---------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.dropdown-item {
    cursor: pointer;
}

.searchDropdownText {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.filter-bar {
    border-radius: 100px;
    background-color: var(--color-white);
    width: 1234px;
    height: fit-content;
    min-height: 102px;
    background: var(--color-white);
    box-shadow: var(--shadow-poster-searchBar);

    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

@media (max-width: 767px) {
    .filter-bar {
        border-radius: 1rem;
    }
}

.in-poster-align {
    position: relative;

}

.in-poster-half-align {
    position: absolute;
    bottom: -50px !important;
    width: 100%;
}

/* Add this to your existing styles or create a new CSS file */

/* .filter-bar .col-sm-12:not(:first-child):not(:last-child){ */
.filter-bar :not(:first-child):not(:last-child) {
    border-left: 1px solid var(--color-white-1);
    padding-left: 15px;
}

.centerBar-item :not(:first-child):not(:last-child) {
    border-left: none;
    padding-left: 15px;
}

@media (max-width: 767px) {
    .filter-bar :not(:first-child):not(:last-child) {
        border-left: none;
        padding-left: 15px;
    }
}

.dropdown-separator {
    width: 100%;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 10px;
    margin-bottom: 10px;
}

.date-picker-custom {
    border: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url('../images/icons/dropdownArrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px;
    cursor: pointer;
}

.dropdown-search-bar::after {
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
}




/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------- Aligns -------------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.row-center {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.row-right {
    display: flex;
    justify-content: flex-end;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------- Headings -------------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.heading-1-home-black {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
}

.heading-1-2-home-black {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.43px;
}

.heading-2-home-black {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
}

.heading-3-home-black {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.17px;
}

.heading-4-home-black {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-style: normal;
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
}


.heading-1-home-white {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
}


.heading-2-home-white {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
}

@media (max-width: 767px) {
    .heading-2-home-white {
        color: var(--color-white);
        font-family: var(--font-poppins);
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
    }
}

.heading-4-home-white {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}





/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- Featured Experiences -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.feature-icon-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.active-underline-featured {
    border-bottom: 6px solid var(--color-black);
    cursor: pointer;
}

.icon-align-featuredExperiences {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-featuredExperiences {
    width: 55px;
    height: 50px;
}

.icon-name-featuredExperiences {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 11.719px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.icon-age-featuredExperiences {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 11.719px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn-featuredExperiences {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------- passionate ------------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.passionate-section {
    background-color: var(--color-darkBlue);
    /* height: calc(100vh - 66px); */
}

.passionate-image {
    height: calc(100vh - 66px);
}



/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- passionateButton.js -----------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.btn-passionate-text {
    display: flex;
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-items: center;
}

.btn-passionate {
    position: relative;
    background-color: transparent;
    border: none;
    color: var(--color-white);
    width: fit-content;
}

.btn-passionate::before {
    content: "";
    margin-left: 1rem;
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 3px;
    background: var(--linear-greenYellow);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
}

.passionate-image-cover {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
}








/* ButtonPassionate.css */
/* ButtonPassionate.css */
.custom-btn-passionate {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 15px;
    background-color: transparent;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid;
    border-image: var(--linear-greenYellow) 1;
    border-image-slice: 1;
    /* Ensure the entire border is filled */
}

.custom-btn-passionate:hover {
    background-color: var(--linear-greenYellow);
    color: red;
    border-color: transparent;
}











/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- communityProfileCard.js -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.community-small-card {
    width: fit-content;
    display: flex;
    padding: 3px 5px 3px 5px;
    border-radius: 15px;
    background: var(--color-white);
    box-shadow: var(--shadow-community-circle-card);
}

.community-profile {
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    align-items: center;
}

.community-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0.5rem;
}

.community-profile-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.community-profile-img {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    overflow: hidden;
}



.trip-title-community {
    color: var(--color-black-1);
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.trips-community {
    color: var(--color-black-1);
    font-family: var(--font-poppins);
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}


.community-card-sunny {
    border: 1px solid var(--color-light-yellow);
}


.profile-card-overlay-sunny {
    position: relative;
    display: flex;
    left: 80%;
    transform: translate(5%, -216%);
}


@media (max-width: 767px) {
    .profile-card-overlay-sunny {
        position: relative;
        display: flex;
        left: 50%;
        left: 0%;
        top: -4rem;
        transform: none;
    }

    .circle-mobile-center {
        display: flex;
        flex-direction: column;
    }
}

.community-card-ashwin {
    border: 1px solid var(--color-light-red);
}

.profile-card-overlay-ashwin {
    position: relative;
    display: flex;
    left: 80%;
    transform: translate(-48%, -290%);
}

.photo-community-circle-ashwin {
    position: relative;
    display: flex;
    left: 80%;
    transform: translate(-140%, -55%);
}

@media (max-width: 767px) {
    .photo-community-circle-ashwin {
        position: relative;
        display: flex;
        left: 150%;
        transform: translate(-255%, -25%);
        width: 50%;
        height: 100%;
    }

    .profile-card-overlay-ashwin {
        position: relative;
        display: flex;
        left: 40%;
        left: 0%;
        top: -5rem;
        transform: none;
    }

    .circle-mobile-center {
        display: flex;
        flex-direction: column;
    }
}

.community-card-girl {
    border: 1px solid var(--color-light-cyan);
}

.profile-card-overlay-girl {
    position: relative;
    display: flex;
    left: 80%;
    transform: translate(-84%, -95%);
}

.photo-community-circle-girl {
    position: relative;
    display: flex;
    left: 59%;
    transform: translate(-84%, -29%);
}

@media (max-width: 767px) {
    .profile-card-overlay-girl {
        position: relative;
        display: flex;
        left: 15%;
        left: 0%;
        top: -5rem;
        transform: none;
    }

    .photo-community-circle-girl {
        position: relative;
        display: flex;
        left: 90%;
        transform: translate(-100%, -20%);
    }

    .circle-mobile-center {
        display: flex;
        flex-direction: column;
    }
}





/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------- BLOGS -----------------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.card-blog-horizontal {
    width: 25rem;
    overflow: hidden;
    border-radius: 20px;
    background: var(--color-white);
    box-shadow: var(--shadow-blogCard-horizontal);
}


.blog-card {
    color: var(--color-grey-1);
    font-family: var(--font-poppins);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.36px;
    text-decoration-line: underline;
}

.blog-card-title {
    color: var(--color-grey-1);
    font-family: var(--font-poppins);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: -0.48px;
}

.blog-card-name {
    color: var(--color-gray-1);
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.42px;
}



/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- ButtonReadMore.js -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.btn-readMore {
    border: none;
    color: var(--color-white);
    background-color: var(--color-blue);
    border-radius: 0.5rem;

    padding: 0.5rem;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.42px;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- travelCardHorizontal.js -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.card-travel-horizontal {
    width: 20rem !important;
    position: relative;
    border: none;
    border-radius: 15px;
    background: var(--color-white);
    box-shadow: var(--shadow-travelCard);
}

.image--cover {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center right;
}

.profile-btn-col-horizontal-card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.profile-name-col-horizontal-card {
    display: flex;
    align-items: center;
}

.img-container {
    position: relative;
    border-radius: 2.5rem;
}

.img-travel-card-horizontal {
    width: 100%;
    border-radius: 2.5rem;
}

.travel-card-img-caption-horizontal {
    position: absolute;
    padding: 0.2rem 1rem 0.2rem 1rem;
    margin-left: 1.5rem;
    top: 30%;
    left: 0;
    background-color: var(--color-yellow);
    transform: translate(0, -50%);
    color: var(--color-black);
    font-size: 16px;
    border-radius: 0rem 2rem 2rem 0rem;
    z-index: 1;
}

.card-horizontal-icon {
    padding-left: 0.5rem;
}

.card-horizontal-plan {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.17px;
}

.horizontal-card-rating {
    color: var(--color-black);
    text-align: right;
    font-family: var(--font-poppins);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
}

.card-horizontal-plan-type {
    color: var(--color-gray);
    font-family: var(--font-poppins);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.card-horizontal-seat-left-numbers {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
}

.card-horizontal-name {
    color: var(--color-blue);
    font-family: var(--font-poppins);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
}

.card-horizontal-master {
    color: var(--color-grey16);
    font-family: var(--font-poppins);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- travelCardVertical.js ---------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */
.card-travel-vertical {
    border: none;
    border-radius: 15px;
    background: var(--color-white);
    box-shadow: var(--shadow-travelCard);
    padding: 1rem;
}

.card-travel-vertical-img {
    border-radius: 15px;
    /* margin-right: 1.5rem; */
}



/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- viewDetaill.js -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.btn-viewDetail {
    background-color: var(--color-blue);
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.5rem;
    border-radius: 2rem;
    border: none;
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- findExperiences.js -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.btn-findExperiences {
    background-color: var(--color-blue);
    color: var(--color-white);
    font-family: var(--font-poppins);
    padding: 0.3rem;
    border-radius: 2rem;
    border: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------- rightArrow.js ---------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.BtnRightArrow {
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: var(--color-white);
    background-color: var(--color-blue);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------- Influnecers ----------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.influnecers-section {
    background-image: var(--poster-influencer);
    height: calc(100vh - 66px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------- How TWYT Works -------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.twyt-works {
    border: none !important;
    border-bottom: 6px solid var(--color-light-orange) !important;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------- Influencer ---------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.influencer-photo {
    height: 400px;
    width: 200px;
    filter: blur(0px);
    transition: filter 0.5s ease;
    border-radius: 15px;
}

.slick-center .influencer-photo {
    filter: none;
    height: 400px;
    width: 215px;
    border-radius: 15px;
}

.position-1 {
    width: 70% !important;
    height: 40% !important;
    margin: 4rem 2rem 2rem 4rem;
    transform: perspective(800px) rotateY(-30deg);

}

.position-2 {
    width: 85% !important;
    height: 40% !important;
    margin: 2rem;
    transform: perspective(800px) rotateY(-20deg);
}

.position-3 {
    filter: none;
    height: 465px !important;
    width: 230px !important;
    border-radius: 15px;
    margin: 0rem 2rem 0rem 2rem;

}

.position-4 {
    width: 85% !important;
    height: 40% !important;
    margin: 2rem;
    transform: perspective(800px) rotateY(20deg);

}

.position-5 {
    width: 70% !important;
    height: 40% !important;
    margin: 4rem 2rem 2rem 0rem;
    transform: perspective(800px) rotateY(30deg);
}










/* Mobile */

.influencer-carousel-mobile {
    width: 100%;
    height: 60vh;
}



/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- passionateAboutTravel.js ---------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.passionate-vertical-slider {
    display: flex;
    overflow: hidden;
    /* padding-right: 10rem; */
    height: calc(100vh - 66px);
}

@media (max-width: 767px) {
    .passionate-vertical-slider {
        display: flex;
        overflow: hidden;
        padding-right: 0rem;
        height: calc(50vh - 66px);
        padding-right: 0rem !important;
    }

}

.passionate-vertical-slider img {
    max-width: 100%;
    vertical-align: middle;
    border: none;
    box-sizing: border-box;
    transition: opacity .2s;
    padding-bottom: 2rem;
}

.passionate-vertical-slider:hover img {
    opacity: 0.5;
}

.passionate-vertical-slider img:hover {
    opacity: 1;
}

.passionate-vertical-slider .single-column {
    animation: var(--animation, none) 50s infinite linear;
}

.passionate-vertical-slider .single-column:hover {
    animation-play-state: paused;
}

.passionate-vertical-slider .single-column:nth-of-type(odd) {
    align-self: flex-end;
    --direction: 50%;
}

@keyframes slide {
    to {
        -webkit-transform: translateY(var(--direction, -50%));
        transform: translateY(var(--direction, -50%));
    }
}

.single-column {
    flex: 1;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------ Button ------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.default-btn {
    background-color: var(--color-blue);
    color: var(--color-white);
    font-family: var(--font-poppins);
    padding: 14px 33px;
    border-radius: 2rem;
    border: none;
    font-size: 18px;
    font-weight: 400;
}

.login-btn {
    background-color: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 14px;
    font-weight: 400;
    padding-inline: 33px;
    height: 100%;
    border-radius: 2rem;
    border: none;
}

.clip-path-bar {
    width: 100%;
    height: 88px;
    background-image: url('../images/section-hr.png');
    background-repeat: repeat-x;
    background-size: auto;
}

.section-highlite-text {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 43px;
    font-weight: 700;
    letter-spacing: -0.43px;
}

.section-small-highlite-text {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.22px;
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------- Round Image and Text ---------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */


.round-container {
    position: relative;
    width: 240px;
    height: 240px;
    background-color: var(--color-white-2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 50%;
    margin-right: 20px;
}

.round-container-half {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.round-container-circle-button {
    position: absolute;
    top: calc(50% - 20px);

    right: -20px;
    width: 40px;
    height: 40px;
    background-color: var(--color-light-green);
    color: var(--color-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.round-container-title {
    color: var(--color-black-1);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.round-container-text {
    color: var(--color-black-1);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-inline: 1rem;
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------- Section Ask Us ------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.section-ask-us {
    background-color: var(--color-white-3);
}


.card-ask-us {
    display: flex;
    padding: 3rem 6rem;
    border-radius: 15px;
    background: var(--color-light-yellow-1);
}

.card-body-ask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.card-ask-us-title {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.32px;
}


@media (max-width: 767px) {
    .card-ask-us {
        display: flex;
        padding: 3rem 2.5rem;
        border-radius: 0px;
        background: var(--color-light-yellow-1);
    }


}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------- Join TWYT Community -----------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.join-community-section {
    background-color: var(--color-white-3)
}

.contact-wrapper {
    position: relative;
}

.contact {
    margin-bottom: -118px;
    position: relative;
    z-index: 2;
    background-image: url('../images/backgound/contactUs.png');
    background-size: 100% 100%;
    box-shadow: var(--shadow-contact);
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 3rem !important;
    padding-inline: 6rem !important;
}

.contact-title {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.contact-sub-title {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.contact-form-control,
.contact-form-control:focus {
    background-color: transparent;
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 20px;
    font-weight: 300;
    border-radius: 10px;
}

.contact-form-control::placeholder {
    color: var(--color-white);
    opacity: 0.8;
}

.contact-subscribe-btn {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 10px;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------- footer ------------------------------------------------------------*/
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.footer {
    background: var(--color-black-3);
    color: var(--color-white);
    padding-top: 12rem !important;
}

.footer-header {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.footer-list-unstyled li a {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    text-decoration: none;
}

.footer-list-unstyled li a:hover {
    text-decoration: underline;
}

.footer-link {
    font-size: 16px;
    color: var(--color-white);
    text-decoration: none;
    margin-left: 3rem;
}

.footer-link:hover {
    text-decoration: underline;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- continueButton.js ------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.btn-continueButton {
    border-radius: 12px;
    background: var(--color-darkBlue);
    color: var(--color-white);


    display: flex;
    width: 431px;
    padding: 16px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.btn-disabled-continue {
    background-color: var(--color-gray-4);
    cursor: not-allowed;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- Login Modal --------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.login-modal-content {
    border-radius: 0px !important;
    width: 1280px !important;
    height: 800px !important;
}

.login-modal-left-section {
    background-color: var(--color-darkBlue);
    width: 50%;
    height: 750px;
    margin: 1.5rem 0rem 0rem 2rem;
    border-radius: 55px;
}

.login-left-image {
    padding-top: 50%;
}

.login-modal-logo {
    display: flex;
    justify-content: center;
}

.text-fullstop {
    color: var(--color-yellow);
    font-family: var(--font-poppins);
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.style-textbox {
    border-radius: 12px;
    border: 1px solid var(--color-gray-2);
    background: var(--color-white, --color-white);
    display: flex;
    width: 431px;
    padding: 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.otp-row {
    display: flex;
    justify-content: center;
}

.otp-box-style {
    display: flex;
    width: 54px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid var(--color-gray-2);
    background: var(--color-white, --color-white);
    height: 56px;
    padding: 8px 22px;
    gap: 8px;
}

.heading-login-modal {
    color: #000;
    font-family: var(--font-poppins);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.28px;
}

.fourth-modal-align {
    margin: 2rem 0rem 2rem 0rem;
}

.modal-intrest-sub-heading {
    color: var(--color-gray-3);
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.16px;
}

.login-modal-head-font-white {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.login-modal-head-font-yellow {
    color: var(--color-yellow);
    font-family: var(--font-poppins);
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.login-modal-head-font-white-modal-three {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 44px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.login-modal-head-font-yellow-modal-three {
    color: var(--color-yellow);
    font-family: var(--font-poppins);
    font-size: 44px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.sub-heading-login-modal {
    color: var(--color-white);
    font-family: var(--font-poppins);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------- Intrest Button in Modal 4 ------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.button-modal {
    display: flex;
    justify-content: center;
}

.intrest-button {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;

    border-radius: 15px;
    border: 0.25px solid var(--color-gray-5);
    background: var(--color-white);
}

.button-text-row {
    width: 50%;
    padding-left: 5rem;
}

.intrest-button-text {
    color: var(--color-black);
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}











/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- HOVER EFFECTS ------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.hover-effect-blue:hover {
    background-color: var(--color-darkBlue) !important;
    color: var(--color-yellow) !important;
}

.hover-effect-white:hover {
    background-color: var(--color-gray-4) !important;
}

.hover-nav-item:hover {
    color: var(--color-white);
}

.hover-foot-item:hover {
    color: var(--color-yellow) !important;
    text-decoration: none !important;
}

.travel-card-image-hover:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.blog-card-image-hover:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.community-small-profile-card-image-hover:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- Become A Trip Master --------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.become-trip-master-container {
    background: var(--color-sky-blue);
    height: calc(100vh - 1rem);
    display: flex;
    justify-content: center;
    align-items: center;
}

.trip-master-card {
    background-color: var(--color-white);
    border-radius: 40px;
    width: 465px;
    padding: 10px 40px 35px 40px;
    text-align: center;

    box-shadow: 9px 8px 0px 8px rgba(0, 0, 0, 2);
    margin: auto;
}

.trip-master-logo-container img {
    max-width: 100%;
    display: flex;
}

.trip-master-quote-container {
    font-style: var(--font-poppins);
    line-height: 140%;
    letter-spacing: -1px;
    font-size: 22px;
    font-weight: 600;
    color: var(--color-black);
}


.img-trip-master {
    position: absolute;
    top: 50px;
    left: 50px;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------- Become A Trip MAster Progress Bar --------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */


.custom-process-trip-master {
    background-color: var(--color-sky-blue);
    border-radius: 0rem;
}

.custom-process-bar-trip-master {
    background-color: var(--color-yellow);
    border-radius: 0rem 0.5rem 0.5rem 0rem;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------ Route Trip Card Horizontal ----------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.trip-route-horizontal-card {
    width: 20rem !important;
    position: relative;
    border: none;
    border-radius: 15px;
    background: var(--color-white);
    box-shadow: var(--shadow-travelCard);
}

.profile-section-route-trip {
    width: fit-content;
    display: flex;
    padding: 3px 5px 3px 5px;
    border-radius: 15px;
}

.profile-pic-route-trip {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-pic-route-trip img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0.5rem;
}

.route-trip-profile-name {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    font-family: var(--font-poppins);
    line-height: 140%;
    text-align: left;
}

.route-trip-profile-title {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-poppins);
    line-height: 140%;
    font-style: normal;
    text-align: left;
}

.route-trip-city {
    color: var(--color-black);
    font-size: 24px;
    line-height: 140%;
    font-weight: 800;
    font-family: var(--font-poppins);
    font-style: normal;
    text-align: left;
}

.route-trip-country {
    color: var(--color-black);
    font-size: 24px;
    line-height: 140%;
    font-weight: 400;
    font-family: var(--font-poppins);
    font-style: normal;
    text-align: left;
}

.route-trip-city-description {
    color: var(--color-black);
    font-size: 16px;
    line-height: 154%;
    font-weight: 400;
    font-family: var(--font-poppins);
    font-style: normal;
    text-align: left;
}

.btn-learnMore {
    background-color: var(--color-darkBlue);
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
}

.pricing-heading {
    color: var(--color-black);
    font-size: 14px;
    line-height: auto;
    font-weight: 400;
    font-family: var(--font-poppins);
    font-style: normal;
    text-align: left;
}

.actual-pricing {
    color: var(--color-black);
    font-size: 16px;
    line-height: auto;
    font-weight: 700;
    font-family: var(--font-poppins);
    font-style: normal;
    text-align: left;
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------- Horizontal Card Travel with multi Images -------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.travel-card-hori-multi {
    width: 100% !important;
    position: relative;
    border: none;
    border-radius: 15px;
    background: var(--color-white);
    box-shadow: var(--shadow-travelCard);
    padding: 1rem;
}

.trip-card-hori-multi-profile-section {
    width: fit-content;
    display: flex;
    padding: 0spx 5px 0px 5px;
    border-radius: 15px;
}

.profile-pic-trip-card-hori-multi {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-pic-trip-card-hori-multi img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.trip-card-hori-multi-profile-name {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-family: var(--font-poppins);
    line-height: 140%;
    text-align: left;
}

.trip-card-hori-multi-profile-title {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-poppins);
    line-height: 0%;
    font-style: normal;
    text-align: left;
}

.travel-card-multi-city-specility {
    color: var(--color-black);
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-poppins);
    line-height: auto;
    font-style: normal;
    text-align: left;
}

.travel-card-multi-city-tour-days {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-poppins);
    line-height: 140%;
    font-style: normal;
    text-align: left;
}

.travel-card-multi-city-location-type {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-poppins);
    line-height: 140%;
    font-style: normal;
    text-align: left;
}

.travel-card-hori-multi-experiance-type {
    color: var(--color-gray-6);
    font-size: 12px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 140%;
    font-style: normal;
    text-align: left;
}

.experiance-title {
    border: none;
    border-radius: 15px;
    background-color: var(--color-light-yellow-2);
    height: 50px;
}

.experiance-button-text {
    color: var(--color-black);
    font-size: 12px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: auto;
    font-style: normal;
    text-align: left;
}

.travel-card-carousel img {
    width: 100%;
    height: auto;
    padding: 5px;
}


.travel-carousel-horizontal-right-arrow {
    position: absolute;
    top: 18rem;
    right: 0.5rem;
}

.travel-carousel-horizontal-left-arrow {
    position: absolute;
    top: 18rem;
    left: 1rem;
}

@media (max-width: 767px) {
    .travel-carousel-horizontal-right-arrow {
        position: absolute;
        top: 20rem;
        right: 1rem;
    }

    .travel-carousel-horizontal-left-arrow {
        position: absolute;
        top: 20rem;
        left: 1rem;
    }
}

.img-travel-card-carousel {
    pointer-events: none;
}





















































/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- Trip Description Page ----------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */


.trip-description-page-image-background {
    height: calc(75vh - 66px);
    margin-top: 66px;
    background-image: var(--poster-trip-description-goa);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------- ProfileDetailsTripDescription ----------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.profile-details-trip-profile-photo {
    position: absolute;
    bottom: -85%;
    max-width: auto;
    overflow: hidden;
    margin-right: -100rem;
}

.trip-discription-trip-master-profile-img {
    padding-right: 11rem;
}


.profile-details-trip-profile-photo {
    position: relative;
    text-align: center;
}

.profile-photo {
    max-width: auto;
    border-radius: 50%;
    border: 0.5rem solid #fff;
    overflow: hidden;
    box-shadow: var(--shadow-community-circle-card);
}

.profile-photo-half-align {
    position: absolute;
    bottom: 6rem !important;
}

.trip-description-profile-title {
    color: var(--color-blue);
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-poppins);
    line-height: auto;
    font-style: normal;
    letter-spacing: 0%;
}

.trip-description-profile-name {
    color: var(--color-blue);
    font-size: 14px;
    font-weight: 700;
    font-family: var(--font-poppins);
    line-height: 0;
    font-style: normal;
    letter-spacing: 0%;
}

.trip-description-page-heading-1 {
    color: var(--color-blue);
    font-size: 32px;
    font-weight: 700;
    font-family: var(--font-poppins);
    line-height: auto;
    font-style: normal;
    text-align: left;
    letter-spacing: 0%;
}


.trip-description-page-information {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-poppins);
    line-height: 21px;
    font-style: normal;
    text-align: left;
    letter-spacing: 2%;
}

.trip-description-page-carousel-bg-card {
    padding-block: 2rem;
    background: var(--color-white);
    border-radius: 15px;
    box-shadow: var(--shadow-travelCard);
}

.trip-description-ticket-card {
    border: 1px solid #E4E4E4;
    border-radius: 15px;
    box-shadow: var(--shadow-travelCard);
    width: auto;
    height: auto;
}

.trip-description-page-card-time {
    color: var(--color-black);
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 14px;
    font-style: normal;
    letter-spacing: 0%;
}

.trip-description-page-card-time-left {
    background-color: #FFF2CA;
    color: var(--color-black);
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    letter-spacing: 0%;
}

.right-section-trip-description {
    padding-top: 12rem;
}

.trip-description-page-date {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    letter-spacing: 0%;
}

.share-fa-icon-size {
    font-size: 1.5rem;
    cursor: pointer;
}

.particular-rating-heading {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    letter-spacing: 0%;
}

.particular-rating-numbers {
    color: var(--color-black);
    font-size: 21px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    letter-spacing: 0%;
}

.particular-rating-overall-heading {
    color: #4D4D4D;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    letter-spacing: 0%;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------- OVERALL RATTING PROGRESS BAR  ------------------------------------------------ */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.overall-rating-border {
    border-left: 0.15rem solid #DADADA;
    padding-inline: 3rem;
}

.overall-rating-num {
    color: #4D4D4D;
    font-size: 18px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 13.5px;
    font-style: normal;
    letter-spacing: 0%;
}

progress {
    width: 100%;
    border-radius: 15px;
    transition: width 300ms ease;
}

progress[value]::-webkit-progress-bar {
    width: 100%;
    height: 10px;
    border-radius: 50px;
    background-color: #d9d9d9;
    transition: width 300ms ease;
}

progress[value]::-webkit-progress-value {
    width: 0;
    border-radius: 15px;
    background-color: var(--color-black-1);
    transition: width 300ms ease;
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------- Review Card  -------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */


.review-card-name {
    color: var(--color-black);
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    letter-spacing: 0%;
}

.review-card-reviews {
    color: var(--color-black);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-poppins);
    line-height: 19px;
    font-style: normal;
    letter-spacing: 2%;
    text-align: left;
}

.review-card {
    background-color: #FFF6DB;
    border-radius: 15px;
    border: none;
    box-shadow: var(--shadow-travelCard);
}


/* --------------------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------- TIMELINE ---------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------------------------- */

.timeline {
    margin: 0 auto;
    position: relative;
    left: 5px;
    width: 80%;
    max-width: 900px;
    margin-top: 16px;
    margin-left: 5%;
}

.timeline-item {
    color: #000000;
    font-family: var(--font-poppins);
    font-size: 16px;
    padding: 0rem 1.5rem 2rem 1.5rem;
    border-left: 4px solid #00477C;
    position: relative;
    list-style-type: none;
    --item-width: calc(100%);
    line-height: 1;



    color: var(--color-black);
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-poppins);
    line-height: 14px;
    font-style: normal;
    letter-spacing: 0%;








}

.timeline-item:last-child {
    border-bottom: none;
    border-left: 4px var(--color-transparent) solid;
}

.timeline-item::after {
    content: attr(data-date);
    position: absolute;
    right: calc(var(--item-width) + 40px);
    top: 0px;
    float: right;
    white-space: nowrap;



    color: #B5B5B5;
    font-size: 12px;
    font-weight: 500;
    font-family: var(--font-poppins);
    line-height: 14px;
    font-style: normal;
    letter-spacing: 0%;
}

.timeline-item::before {
    content: "";
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #00477C;
    position: absolute;
    left: -10px;
    top: 0px;
}

/* .timeline-item:last-child::before {
    content: "";
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #00477C;
    position: absolute;
    left: -10px;
    top: 0px;
} */























































/* Login.js */
.left-image-section-login {
    background-color: var(--color-darkBlue);
    margin-top: 66px;
    border-radius: 50px;
    height: calc(100vh - 66px);   
}
















































































































































































































































































































































































































































































































































































































































/* BACKUP of UNKNOWN pages */
/* Trip Page */

.trip-poster-heading {
    color: var(--color-white);
    font-size: 54px;
    font-weight: 700;
    font-style: normal;
    font-family: var(--font-poppins);
    line-height: 139%;
    text-align: left;
}


.goa-image-background {
    height: calc(75vh - 66px);
    margin-top: 66px;
    background-image: var(--poster-goa);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.package-heading-trip-page-black {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 2%;
}

.package-desc-trip-page-black {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 178%;
    letter-spacing: 2%;
}

.profile-photo-trip-page {
    border: 1rem var(--color-white) solid;
    border-radius: 50%;
    box-shadow: var(--shadow-community-circle-card);
}

.profile-photo-tag-trip-page {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: auto;
    letter-spacing: 0%;
}

.profile-photo-name-trip-page {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0%;
}

.card-trip {
    position: relative;
    z-index: 2;
    background-size: 100% 100%;
    box-shadow: var(--shadow-travelCard);
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 3rem !important;
    padding-inline: 6rem !important;
}


.book-now-btn {
    background-color: var(--color-white);
    color: var(--color-blue);
    border-radius: 15px;
    border: 3px var(--color-blue) solid;

    font-size: 16px;
    font-weight: 700;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    text-align: center;
}

.invite-to-tribe-btn {
    background-color: var(--color-blue);
    color: var(--color-white);
    border: 3px var(--color-blue) solid;
    border-radius: 15px;

    font-size: 16px;
    font-weight: 700;
    font-family: var(--font-poppins);
    line-height: 40px;
    font-style: normal;
    text-align: center;
}

.price-card-trip-page {
    color: #606060;
    font-size: 30px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 36.57px;
    font-style: normal;
    text-align: left;
}

.rs-sign-trip-page {
    color: var(--color-black);
    font-size: 30px;
    font-weight: 600;
    font-family: var(--font-poppins);
    line-height: 36.57px;
    font-style: normal;
    text-align: left;
}

.trip-carousel-description {
    color: var(--color-black);
    font-family: var(--font-poppins);
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;

}

.trip-itinerary-circle-image {
    border-radius: 50%;
    border: 3px var(--color-yellow) solid;
    padding: 10px;
}

.trip-itinerary-circle-image-day {
    color: var(--color-blue);
    font-family: var(--font-poppins);
    font-style: normal;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}





/* tripPageCarousel.js */
.slider-container {
    position: relative;
}

.trip-page-carousel-left-arrow,
.trip-page-carousel-right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.trip-page-carousel-left-arrow {
    left: 10px;
}

.trip-page-carousel-right-arrow {
    right: 10px;
}











/* Increment Decremnet */



.quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    /* border-block: 1px solid black;
    border-radius: 15px; */
    /* box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25); */
}

.quantity__minus,
.quantity__plus {
    display: block;
    width: 22px;
    height: 23px;
    margin: 0;
    background: var(--color-blue);
    text-decoration: none;
    text-align: center;
    line-height: 23px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    border: none;
    font-size: 16px;
}

.quantity__minus:hover,
.quantity__plus:hover {
    background: #575b71;
    color: #fff;
}

.quantity__minus {
    border-radius: 50%;
}

.quantity__plus {
    border-radius: 50%;
}

.quantity__input {
    width: 32px;
    height: 19px;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none;
    background: #fff;
    color: #8184a1;
}

.quantity__minus:link,
.quantity__plus:link {
    color: #8184a1;
}

.quantity__minus:visited,
.quantity__plus:visited {
    color: #fff;
}